import { ReactNode, useCallback, useEffect, useState } from "react";
import gql from "graphql-tag";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

import {
  AccountPasswordLoginForm,
  AccountPasswordLoginFormState,
} from "auth/components/AccountPasswordLoginForm";
import {
  buildOrganizationCreationFrontendData,
  OrganizationCreationPayload,
  scheduleOrganizationCreation,
} from "auth/utils/signup";
import { Submit } from "components/Button/Submit";
import { EnvironmentBanner } from "components/EnvironmentBanner/EnvironmentBanner";
import { FormCheckbox } from "components/Form/CheckBox/FormCheckbox";
import { Form } from "components/Form/Form/Form";
import { FormInput } from "components/Form/Input/FormInput";
import { FormSelect } from "components/Form/Select/FormSelect";
import { ClickableIcon } from "components/Icon/ClickableIcon";
import { PublicScreenWrapper } from "components/PublicScreenWrapper/PublicScreenWrapper";
import { InfoTooltip } from "components/Tooltip/InfoTooltip";
import { AppErrorCode } from "errors/generated";
import {
  CustomerEnvironmentKnownValues,
  NablaRegionKnownValue,
  NablaRegionKnownValues,
} from "generated/account";
import { CreateAccount } from "generated/unauth-account";
import { ParsedGraphQLError } from "graphql-client/errors";
import { useMutation } from "graphql-client/useMutation";
import { useFormState } from "hooks/useFormState";
import { useIsDesktop } from "hooks/useMediaQuery";
import { useTranslation } from "i18n";
import { Translation } from "i18n/Translation";
import { trackEvent } from "tracking";
import { run } from "utils";
import { attributionData, trackConversion } from "utils/attribution";
import { notifier } from "utils/notifier";

gql`
  # schema = UNAUTHENTICATED_ACCOUNT
  mutation CreateAccount(
    $email: String!
    $region: NablaRegion!
    $timezone: TimeZone!
    $frontendData: String!
  ) {
    createAccount(
      email: $email
      region: $region
      locale: ENGLISH
      timezone: $timezone
      accountCreationEmail: {
        chooseCredentials: { product: CORE_API, frontendData: $frontendData }
      }
    ) {
      _
    }
  }
`;

export const CoreApiSignup = () => <Signup />;

// ------ Generic signup flow.

type SignupState =
  | { step: "SIGNUP_FORM"; initialValues?: SignupFormValues }
  | { step: "EMAIL_CONFIRMATION"; email: string }
  | { step: "LOGIN_FORM"; signupFormValues: SignupFormValues };

const Signup = () => {
  const t = useTranslation();
  const [createAccount] = useMutation(CreateAccount, {
    throwOnError: true,
  });
  const [signupState, setSignupState] = useState<SignupState>({
    step: "SIGNUP_FORM",
  });

  const createAccountOrShowLogin = useCallback(
    async (signupFormValues: SignupFormValues) => {
      const {
        firstName,
        lastName,
        email,
        organizationName,
        region,
        customerEnvironment,
      } = signupFormValues;

      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      if (!customerEnvironment) {
        throw new Error(
          "customerEnvironment should never be undefined since it's required",
        );
      }
      // The creation of the organization and the initial identity will
      // only happen after the user picks credentials for the newly
      // created account.
      const organizationCreationPayload = run(
        (): OrganizationCreationPayload => ({
          type: "COPILOT_API_ORGANIZATION",
          organization: {
            displayName: organizationName,
            customerEnvironment,
            locale: "ENGLISH",
            attributionData: attributionData
              ? JSON.stringify(attributionData)
              : null,
            timezone,
          },
          initialCopilotApiIdentity: {
            firstName,
            lastName,
            roles: ["ADMINISTRATOR", "PRACTITIONER"],
          },
        }),
      );

      try {
        await createAccount(
          {
            email,
            region,
            timezone,
            frontendData: buildOrganizationCreationFrontendData(
              organizationCreationPayload,
            ),
          },
          { requestContext: { region } },
        );

        trackEvent({ name: "Start Sign Up" });
        trackConversion("SignupFormSubmitted");
        setSignupState({ step: "EMAIL_CONFIRMATION", email });
      } catch (error) {
        if (
          error instanceof ParsedGraphQLError &&
          (error.code === AppErrorCode.ACCOUNT_ALREADY_EXISTS ||
            error.code ===
              AppErrorCode.ACCOUNT_ALREADY_EXISTS_IN_ANOTHER_REGION)
        ) {
          await scheduleOrganizationCreation(
            email,
            organizationCreationPayload,
          );

          setSignupState({ step: "LOGIN_FORM", signupFormValues });
        } else {
          notifier.error({
            user: t("signup.error"),
            sentry: { exception: error },
          });
        }
      }
    },
    [createAccount, t],
  );

  return (
    <PublicScreenWrapper image="COPILOT">
      <EnvironmentBanner />

      <div className="w-full max-w-[450px] flex-col">
        {run(() => {
          switch (signupState.step) {
            case "SIGNUP_FORM":
              return (
                <SignupForm
                  initialValues={signupState.initialValues}
                  onSubmit={createAccountOrShowLogin}
                />
              );

            case "EMAIL_CONFIRMATION":
              return <SignupEmailConfirmation email={signupState.email} />;

            case "LOGIN_FORM":
              return (
                <LoginForm
                  email={signupState.signupFormValues.email}
                  onGoBack={() =>
                    setSignupState({
                      step: "SIGNUP_FORM",
                      initialValues: signupState.signupFormValues,
                    })
                  }
                />
              );
          }
        })}
      </div>
    </PublicScreenWrapper>
  );
};

// ----- Signup form.

type SignupFormValues = {
  firstName: string;
  lastName: string;
  email: string;
  organizationName: string;
  region: NablaRegionKnownValue;
  legalAgreement: boolean;
  customerEnvironment: "TEST" | "PRODUCTION" | undefined;
};

const SignupForm = ({
  initialValues,
  onSubmit,
}: {
  initialValues: SignupFormValues | undefined;
  onSubmit: (payload: SignupFormValues) => Promise<void>;
}) => {
  const t = useTranslation();
  return (
    <>
      <div className="title mb-44 leading-tight">
        <Translation
          k="signup.title"
          components={{ b: (props) => <b {...props} /> }}
        />
      </div>
      <Form<SignupFormValues>
        className="flex-col space-y-16 w-full"
        initialValues={
          initialValues ?? {
            firstName: "",
            lastName: "",
            email: "",
            organizationName: "",
            region: "US",
            legalAgreement: false,
            customerEnvironment: undefined,
          }
        }
        enableReinitialize={false}
        validationSchema={{
          firstName: "required",
          lastName: "required",
          email: "required",
          organizationName: Yup.string()
            .required(t("form.form.form.this_field_is_required"))
            .trim()
            .min(2, t("signup.organization_name_too_short")),
          region: "required",
          legalAgreement: "requiredCheckbox",
          customerEnvironment: "required",
        }}
        onSubmit={onSubmit}
      >
        <SignupFormContent />
      </Form>
    </>
  );
};

const SignupFormContent = () => {
  const t = useTranslation();
  const isDesktop = useIsDesktop();
  const { values, validateForm } = useFormState<SignupFormValues>();

  useEffect(() => {
    void validateForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t]);

  return (
    <>
      <FormInput
        name="firstName"
        label={t("signup.signup.first_name")}
        placeholder={t("signup.signup.john")}
        wrapperClassName="flex-fill justify-between"
        autoFocus={isDesktop}
      />
      <FormInput
        name="lastName"
        label={t("signup.signup.last_name")}
        placeholder={t("signup.signup.fischer")}
        wrapperClassName="flex-fill justify-between"
      />
      <FormInput
        name="email"
        label={t("signup.signup.email")}
        placeholder={t("signup.signup.johnfischercom")}
        wrapperClassName="flex-fill justify-between"
      />
      <FormInput
        name="organizationName"
        label={t("signup.signup.organization_name")}
        placeholder={t("signup.signup.ada_health")}
        wrapperClassName="flex-fill justify-between"
      />
      <FormSelect
        label={t("signup.signup.environment")}
        name="customerEnvironment"
        placeholder={t("signup.signup.environment_placeholder")}
        required
        options={CustomerEnvironmentKnownValues}
        wrapperClassName="flex-fill justify-between"
        position="bottom"
        getOptionLabel={(v) =>
          ({
            TEST: "Test",
            PRODUCTION: "Production",
          }[v])
        }
      />
      <FormSelect
        label={t("signup.signup.region")}
        name="region"
        hint={
          <InfoTooltip
            label={t("signup.signup.region_tooltip")}
            position="left"
          />
        }
        options={NablaRegionKnownValues}
        wrapperClassName="flex-fill justify-between"
        position="bottom"
        getOptionLabel={(v) =>
          ({
            EU: t("utils.nabla_region.eu"),
            US: t("utils.nabla_region.us"),
          }[v])
        }
      />
      <div className="flex-col gap-10 mt-30 text-12">
        <FormCheckbox
          name="legalAgreement"
          className="text-12"
          label={
            <span className="flex-shrink text-12">
              {run(() => {
                switch (values.region) {
                  case "EU":
                    return (
                      <Translation
                        k="signup.legal.eu_disclaimer"
                        components={{ tosLink: TosLink, dpaLink: DpaLink }}
                      />
                    );
                  case "US":
                    return (
                      <Translation
                        k="signup.legal.us_disclaimer"
                        components={{ tosLink: TosLink, baaLink: BaaLink }}
                      />
                    );
                }
              })}
            </span>
          }
        />
      </div>
      <Submit large className="mt-30" label={t("signup.signup.continue")} />
    </>
  );
};

const TosLink = ({ children }: { children: ReactNode }) => {
  const t = useTranslation();
  return (
    <a
      className="link"
      target="_blank"
      rel="noreferrer"
      href={t("signup.legal.tos_url")}
    >
      {children}
    </a>
  );
};

const DpaLink = ({ children }: { children: ReactNode }) => {
  const t = useTranslation();
  return (
    <a
      className="link"
      target="_blank"
      rel="noreferrer"
      href={t("signup.legal.dpa_url")}
    >
      {children}
    </a>
  );
};

const BaaLink = ({ children }: { children: ReactNode }) => (
  <a
    className="link"
    target="_blank"
    rel="noreferrer"
    // The BAA is not translated since it's meant to be for US use only.
    href="https://www.nabla.com/docs/business-associate-agreement"
  >
    {children}
  </a>
);

const SignupEmailConfirmation = ({ email }: { email: string }) => {
  const t = useTranslation();
  return (
    <>
      <div className="title leading-snug mb-24">
        {t("signup.confirmation.title")}
      </div>
      <div className="text-body text-16">
        <Translation
          k="signup.confirmation.subtitle"
          components={{ b: (props) => <b {...props} /> }}
          values={{ email }}
        />
      </div>
    </>
  );
};

// ----- Optional account login step.

const LoginForm = ({
  email,
  onGoBack,
}: {
  email: string;
  onGoBack: () => void;
}) => {
  const t = useTranslation();
  const navigate = useNavigate();
  const [passwordLoginFormState, setPasswordLoginFormState] =
    useState<AccountPasswordLoginFormState>({ step: "CREDENTIALS_FORM" });

  if (passwordLoginFormState.step !== "CREDENTIALS_FORM") {
    return (
      <AccountPasswordLoginForm
        state={passwordLoginFormState}
        onStateChange={setPasswordLoginFormState}
        fixedEmail={email}
        onSuccess={() => navigate("/")}
      />
    );
  }

  return (
    <>
      <ClickableIcon
        name="chevron"
        rotate={180}
        className="mb-24 border rounded-full h-44 w-44"
        onClick={onGoBack}
      />

      <p className="mb-24">{t("signup.please_login")}</p>

      <AccountPasswordLoginForm
        state={passwordLoginFormState}
        onStateChange={setPasswordLoginFormState}
        fixedEmail={email}
        onSuccess={() => navigate("/")}
      />
    </>
  );
};
